import React, { useCallback, useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
    useLocation
} from "react-router-dom";
import { useCookies } from "react-cookie";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test secret API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

const CheckoutForm = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const plan_level = queryParams.get('plan_level') || '1'; // 默认为 '1' 如果没有提供
    const [cookies] = useCookies(['Authorization']);

    const fetchClientSecret = useCallback(() => {
        // 创建一个 Checkout 会话
        return fetch(process.env.REACT_APP_BASE_URL + "place_order", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies.Authorization
            },
            body: JSON.stringify({
                plan_level: plan_level
            }),
        })
            .then((res) => res.json())
            .then((data) => data.clientSecret);
    }, [plan_level]);

    const options = { fetchClientSecret };

    return (
        <div id="checkout">
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    )
}

export default CheckoutForm;