import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        lng: localStorage.getItem('userLanguage') || 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    common: {
                        email: 'Email',
                        password: 'Password',
                        confirm_password: 'Confirm password',
                        first_name: 'First name',
                        last_name: 'Last name',
                        telephone: 'Phone',
                        optional: 'Optional',
                        send: 'Send',
                        submit: 'Submit',
                        cancel: 'Cancel',
                        confirm: 'Confirm',
                        back: 'Back',
                        details: 'Details',
                        question: 'Question',
                        site_busy_error: 'The site is busy, please try again later',
                        coming_soon: 'Coming soon ...',
                    },
                    checkout: {
                        days_full_access: 'days full access',
                        day: 'day',
                        off: 'off',
                        discount: 'discount',
                        subscribe: 'Subscribe',
                        subscription_note: 'For more information, please contact us.',
                        contact_us: 'Contact Us',
                        order_available_within: "Order valid for",
                        scan_wechat_to_pay: "Scan WeChat QR code to pay",
                        confirm_payment: "Confirm payment",
                        order_expired: "Order expired",
                        payment_completed: "Payment completed",
                        payment_not_completed: "Payment not completed",
                    },
                    checkout_result: {
                        go_to_homepage: 'Go to Homepage',
                        go_to_subscription: 'Go to Subscription',
                        subscription_succeed: 'Subscription Confirmed!',
                        subscription_failed: 'Subscription Failed!',
                    },
                    header: {
                        homepage: 'Home',
                        objective_testing: 'Objective testing',
                        listening_comprehension: 'Listening Comprehension',
                        reading_comprehension: 'Reading Comprehension',
                        subjective_testing: 'Subjective testing',
                        speaking_comprehension: 'Expression Orale',
                        writing_comprehension: 'Expression Écrite',
                        Subscription: 'Subscription',
                        my_profile: "My Profile",
                    },
                    login: {
                        sign_up: 'Sign up',
                        login: 'Login',
                        login_field_missing: 'Email or password is missing',
                        login_failed_mismatch: 'Login failed, please check your username and password',
                        login_failed_server: 'Login failed, please try again later',
                        logout: 'Logout',
                    },
                    register: {
                        email_verification_code: "Email verification code",
                        send_verification_code: "Send verification code",
                        enter_CAPTCHA: "Enter CAPTCHA",
                        register_field_missing: 'Email, password, first name, last name, and CAPTCHA code are required',
                        register_succeed: "You have registered successfully!",
                        register_failed_later: "Failed to register, please try again later",
                        back_to_login: "Back to previous page",
                    },
                    homepage: {
                        heading: "TCF Canada Master Key",
                        slogan: "The ultimate solution to ace it",
                        info_heading: "About TCF Canada",
                        info_slogan: "Everything you need to know to get started",
                        writing_topic: 'Recent Writing Topics',
                        listening_topic: 'Recent Listening Topics',
                        insight: 'Insights',
                        test_center: 'Test Centers',
                        official_link: 'Official Links',
                    },
                    profile: {
                        no_plan: "Don't have a plan",
                        membership_intro: "Your full access will be available until",
                        extend_membership: "Extend membership",
                        update_password: "Update password",
                        account: "Account",
                        check_subscription: "Check subscription plans",
                        non_membership_header: "Become a full member to access to the following resources",
                        membership_header: "You are a full member, with access to the following resources",
                        membership_right_1: "36 real TCF Canada listening tests + unlimited AI tutoring",
                        membership_right_2: "37 real TCF Canada reading tests + unlimited AI tutoring",
                        membership_right_3: "Up-to-date speaking topics from real TCF exams + AI assessment and tutoring",
                        membership_right_4: "Up-to-date writing topics from real TCF exams + AI assessment and tutoring",
                        change_password_field_missing: "Required fields are missing",
                        password_not_match: "Password doesn't match",
                        change_password_failed: "Failed to change password",
                    },
                    comprehension: {
                        comprehension_test_structure: 'Structure of the comprehension test',
                        become_member_intro: "You need to become a full member to access all real tests.",
                        become_member_button: "Become a member",
                        listening_test_label_prefix: "CO - Test ",
                        reading_test_label_prefix: "CE - Test ",
                        test: "Test",
                        train: "Train",
                    },
                    test_question: {
                        previous_question: "Previous question",
                        next_question: "Next question",
                        transcript: "Transcript",
                        breakdown: "Breakdown",
                        show_transcript: "Show Transcript",
                        show_breakdown: "Show Breakdown",
                        original_text: "Original Text",
                        translation: "Translation",
                        key_vocabulary_and_expressions: "Key Vocabulary and Expressions",
                        reference_answer: "Reference Answer",
                        show_analysis: "Show Analysis",
                        summary: "Summary",
                        translation_analysis: "Translation and analysis",
                        options: 'Options',
                        explanation: 'Explanation',
                        conclusion: 'Conclusion',
                        finish: "Finish",
                        confirm_finish_test: "Confirm to finish the test?",
                    },
                    test_result: {
                        test_result: "Test result",
                        correct: "Correct",
                        wrong: "Wrong",
                        answered: "Answered",
                        not_answered: "Not answered",
                        total_score: "Total score",
                        score: "Score",
                        correct_answer: "Correct answer",
                        your_answer: "Your answer",
                        under_levels: "Under 4"
                    }
                }
            },
            fr: {
                translation: {
                    common: {
                        email: 'Courriel',
                        password: 'Mot de Passe',
                        confirm_password: 'Confirmez mot de passe',
                        first_name: 'Prénom',
                        last_name: 'Nom',
                        telephone: 'Téléphone',
                        optional: 'facultatif',
                        send: 'Envoyer',
                        submit: 'S’inscrire',
                        cancel: 'Annuler',
                        confirm: 'Confirmer',
                        back: 'Retour',
                        details: 'Détails',
                        question: 'Question',
                        site_busy_error: 'Le site est occupé, veuillez réessayer plus tard',
                        coming_soon: 'Bientôt disponible ...',
                    },
                    header: {
                        homepage: 'Accueil',
                        objective_testing: 'Épreuves d\'Objectives',
                        listening_comprehension: 'Compréhension Orale',
                        reading_comprehension: 'Compréhension Écrite',
                        subjective_testing: 'Épreuves d\'Expressions',
                        speaking_comprehension: 'Expression Orale',
                        writing_comprehension: 'Expression Écrite',
                        Subscription: 'Abonnement',
                        my_profile: "Mon profil",
                    },
                    checkout: {
                        days_full_access: 'jours d\'accès complet',
                        day: 'jour',
                        off: 'de réduction',
                        discount: 'réduction',
                        subscribe: 'S\'abonner',
                        subscription_note: 'Pour plus d\'informations, veuillez nous contacter.',
                        contact_us: 'Contactez-nous',
                        order_available_within: "Commande valable pendant",
                        scan_wechat_to_pay: "Scannez le code QR WeChat pour payer",
                        confirm_payment: "Confirmer le paiement",
                        order_expired: "Commande expirée",
                        payment_completed: "Paiement effectué",
                        payment_not_completed: "Paiement non effectué",
                    },
                    checkout_result: {
                        go_to_homepage: 'Aller à la page d\'accueil',
                        go_to_subscription: 'Aller à l\'abonnement',
                        subscription_succeed: 'Abonnement confirmé!',
                        subscription_failed: 'Échec de l\'abonnement!',
                    },
                    login: {
                        sign_up: 'S\'inscrire',
                        login: 'Se connecter',
                        login_field_missing: 'L\'email ou le mot de passe est manquant',
                        login_failed_mismatch: 'Échec de la connexion, veuillez vérifier votre nom d\'utilisateur et votre mot de passe',
                        login_failed_server: 'Échec de la connexion, veuillez réessayer plus tard',
                        logout: 'Se déconnecter',
                    },
                    register: {
                        email_verification_code: "Code de vérification du courriel",
                        send_verification_code: "Envoyer le code de vérification",
                        enter_CAPTCHA: "Entrez le CAPTCHA",
                        register_field_missing: 'L\'adresse électronique, le mot de passe, le prénom, le nom de famille et le code CAPTCHA sont obligatoires.',
                        register_succeed: "Vous vous êtes bien inscrit(e) !",
                        register_failed_later: "Échec de l'inscription, veuillez réessayer plus tard",
                        back_to_login: "Retourner à la page précédente",
                    },
                    homepage: {
                        heading: "TCF Canada Master Key",
                        slogan: "La solution ultime pour réussir",
                        info_heading: "À propos du TCF Canada",
                        info_slogan: "Tout ce que vous devez savoir pour commencer",
                        writing_topic: 'Sujets d\'écriture récents',
                        listening_topic: 'Sujets d\'écoute récents',
                        insight: 'Aperçu',
                        test_center: 'Centres d\'examen',
                        official_link: 'Liens officiels',
                    },
                    profile: {
                        no_plan: "Ne pas avoir de plan",
                        membership_intro: "Votre accès complet sera disponible jusqu'à",
                        extend_membership: "Prolonger l'adhésion",
                        update_password: "Mettre à jour le mot de passe",
                        account: "Compte",
                        check_subscription: "Vérifier les plans d'abonnement",
                        non_membership_header: "Devenez un membre à part entière pour accéder aux ressources suivantes",
                        membership_header: "Vous êtes membre à part entière et avez accès aux ressources suivantes",
                        membership_right_1: "36 tests d'écoute réels du TCF Canada + tutorat AI illimité",
                        membership_right_2: "37 vrais tests de lecture du TCF Canada + tutorat AI illimité",
                        membership_right_3: "Sujets d'expression orale actualisés tirés d'examens réels du TCF + évaluation et tutorat AI",
                        membership_right_4: "Sujets d'écriture actualisés tirés d'examens TCF réels + évaluation et tutorat AI",
                        change_password_field_missing: "Les champs obligatoires sont manquants",
                        password_not_match: "Le mot de passe ne correspond pas",
                        change_password_failed: "Échec du changement de mot de passe",
                    },
                    comprehension: {
                        comprehension_test_structure: 'Structure du test de compréhension',
                        become_member_intro: "Vous devez devenir membre à part entière pour accéder à tous les vrais tests.",
                        become_member_button: "Devenir membre",
                        listening_test_label_prefix: "CO - Test ",
                        reading_test_label_prefix: "CE - Test ",
                        test: "Test",
                        train: "Train",
                    },
                    test_question: {
                        previous_question: "Question précédente",
                        next_question: "Question suivante",
                        transcript: "Transcription",
                        breakdown: "Détails",
                        show_transcript: "Afficher la transcription",
                        show_breakdown: "Afficher les détails",
                        original_text: "Texte original",
                        translation: "Traduction",
                        key_vocabulary_and_expressions: "Vocabulaire clé et expressions",
                        reference_answer: "Réponse de référence",
                        show_analysis: "Afficher l'analyse",
                        summary: "Résumé",
                        translation_analysis: "Traduction et analyse",
                        options: 'Options',
                        explanation: 'Explication',
                        conclusion: 'Conclusion',
                        finish: "Terminer",
                        confirm_finish_test: "Confirmer pour terminer le test?",
                    },
                    test_result: {
                        test_result: "Résultat du test",
                        correct: "Correct",
                        wrong: "erreur",
                        answered: "Répondu",
                        not_answered: "Non répondu",
                        total_score: "Score total",
                        score: "Score",
                        correct_answer: "Réponse correcte",
                        your_answer: "Votre réponse",
                        under_levels: "Sous 4"
                    }
                }
            },
            'zh-CHS': {
                translation: {
                    common: {
                        email: '电子邮件',
                        password: '密码',
                        confirm_password: '确认密码',
                        first_name: '名字',
                        last_name: '姓氏',
                        telephone: '电话',
                        optional: '可选',
                        send: '发送',
                        submit: '提交',
                        cancel: '取消',
                        confirm: '确认',
                        back: '返回',
                        details: '细节',
                        question: '问题',
                        site_busy_error: '网站繁忙，请稍后再试',
                        coming_soon: '即将推出 ...',
                    },
                    header: {
                        homepage: '主页',
                        objective_testing: '客观测试',
                        listening_comprehension: '听力测试',
                        reading_comprehension: '阅读测试',
                        subjective_testing: '主观测试',
                        speaking_comprehension: '口语测试',
                        writing_comprehension: '写作测试',
                        Subscription: '订阅',
                        my_profile: "我的资料",
                    },
                    checkout: {
                        days_full_access: '天完整权益',
                        day: '天',
                        off: '折扣',
                        discount: '折扣',
                        subscribe: '订阅',
                        subscription_note: '有关更多信息，请与我们联系。',
                        contact_us: '联系我们',
                        order_available_within: "订单有效期",
                        scan_wechat_to_pay: "扫描微信二维码支付",
                        confirm_payment: "确认支付",
                        order_expired: "订单已过期",
                        payment_completed: "支付完成",
                        payment_not_completed: "支付未完成",
                    },
                    checkout_result: {
                        go_to_homepage: '转到主页',
                        go_to_subscription: '转到订阅',
                        subscription_succeed: '订阅成功！',
                        subscription_failed: '订阅失败！',
                    },
                    login: {
                        sign_up: '注册',
                        login: '登录',
                        login_field_missing: '缺少电子邮件或密码',
                        login_failed_mismatch: '登录失败，请检查您的用户名和密码',
                        login_failed_server: '登录失败，请稍后再试',
                        logout: '登出',
                    },
                    register: {
                        email_verification_code: "电子邮件验证码",
                        send_verification_code: "发送验证码",
                        enter_CAPTCHA: "输入验证码",
                        register_field_missing: '需要电子邮件、密码、名字、姓氏和验证码',
                        register_succeed: "您已成功注册！",
                        register_failed_later: "注册失败，请稍后再试",
                        back_to_login: "返回登录页面",
                    },
                    homepage: {
                        heading: "TCF Canada Master Key",
                        slogan: "终极备考工具，开启上岸坦途",
                        info_heading: "关于 TCF Canada",
                        info_slogan: "备考入门手册",
                        writing_topic: '最新写作题目',
                        listening_topic: '最新听力题目',
                        insight: '见解',
                        test_center: '考点信息',
                        official_link: 'IRCC官方链接',
                    },
                    profile: {
                        no_plan: "未订阅",
                        membership_intro: "您的完整访问权限将持续到",
                        extend_membership: "延长会员资格",
                        update_password: "更新密码",
                        account: "账户",
                        check_subscription: "查看订阅页面",
                        non_membership_header: "订阅以访问以下资源",
                        membership_header: "您是会员，可以访问以下资源",
                        membership_right_1: "36个真实的 TCF Canada 听力测试 + 无限 AI 辅导",
                        membership_right_2: "37个真实的 TCF Canada 阅读测试 + 无限 AI 辅导",
                        membership_right_3: "来自真实 TCF 考试的最新口语试题 + AI 评估和辅导",
                        membership_right_4: "来自真实 TCF 考试的最新写作试题 + AI 评估和辅导",
                        change_password_field_missing: "缺少必填字段",
                        password_not_match: "密码不匹配",
                        change_password_failed: "密码更改失败",
                    },
                    comprehension: {
                        comprehension_test_structure: '客观测试题目结构',
                        become_member_intro: "您需要成为会员才能访问所有的试题。",
                        become_member_button: "成为会员",
                        listening_test_label_prefix: "CO - 听力测试 ",
                        reading_test_label_prefix: "CE - 阅读测试 ",
                        test: "测试",
                        train: "练习",
                    },
                    test_question: {
                        previous_question: "上一题",
                        next_question: "下一题",
                        transcript: "原文",
                        breakdown: "详解",
                        show_transcript: "显示原文",
                        show_breakdown: "显示详解",
                        original_text: "原文",
                        translation: "翻译",
                        key_vocabulary_and_expressions: "关键词汇和表达",
                        reference_answer: "参考答案",
                        show_analysis: "显示分析",
                        summary: "总结",
                        translation_analysis: "翻译和分析",
                        options: '选项',
                        explanation: '解释',
                        conclusion: '结论',
                        finish: "完成",
                        confirm_finish_test: "确认完成测试？",
                    },
                    test_result: {
                        test_result: "测试结果",
                        correct: "正确",
                        wrong: "错误",
                        answered: "已回答",
                        not_answered: "未回答",
                        total_score: "总分",
                        score: "得分",
                        correct_answer: "正确答案",
                        your_answer: "您的答案",
                        under_levels: "低于 4"
                    },
                }
            },
            'zh-CHZ': {
                translation: {
                    common: {
                        email: '電子郵件',
                        password: '密碼',
                        confirm_password: '確認密碼',
                        first_name: '名字',
                        last_name: '姓氏',
                        telephone: '電話',
                        optional: '可選',
                        send: '發送',
                        submit: '提交',
                        cancel: '取消',
                        confirm: '確認',
                        back: '返回',
                        details: '細節',
                        question: '問題',
                        site_busy_error: '網站繁忙，請稍後再試',
                        coming_soon: '即將推出 ...',
                    },
                    header: {
                        homepage: '主頁',
                        objective_testing: '客觀測試',
                        listening_comprehension: '聽力測試',
                        reading_comprehension: '閱讀測試',
                        subjective_testing: '主觀測試',
                        speaking_comprehension: '口語測試',
                        writing_comprehension: '寫作測試',
                        Subscription: '訂閱',
                        my_profile: "我的資料",
                    },
                    checkout: {
                        days_full_access: '天完整權益',
                        day: '天',
                        off: '折扣',
                        discount: '折扣',
                        subscribe: '訂閱',
                        subscription_note: '有關更多信息，請與我們聯繫。',
                        contact_us: '聯繫我們',
                        order_available_within: "訂單有效期",
                        scan_wechat_to_pay: "掃描微信二維碼支付",
                        confirm_payment: "確認支付",
                        order_expired: "訂單已過期",
                    },
                    checkout_result: {
                        go_to_homepage: '轉到主頁',
                        go_to_subscription: '轉到訂閱',
                        subscription_succeed: '訂閱成功！',
                        subscription_failed: '訂閱失敗！',
                    },
                    login: {
                        sign_up: '註冊',
                        login: '登錄',
                        login_field_missing: '缺少電子郵件或密碼',
                        login_failed_mismatch: '登錄失敗，請檢查您的用戶名和密碼',
                        login_failed_server: '登錄失敗，請稍後再試',
                        logout: '登出',
                    },
                    register: {
                        email_verification_code: "電子郵件驗證碼",
                        send_verification_code: "發送驗證碼",
                        enter_CAPTCHA: "輸入驗證碼",
                        register_field_missing: '需要電子郵件、密碼、名字、姓氏和驗證碼',
                        register_succeed: "您已成功註冊！",
                        register_failed_later: "註冊失敗，請稍後再試",
                        back_to_login: "返回登錄頁面",
                    },
                    homepage: {
                        heading: "TCF Canada Master Key",
                        slogan: "成功的終極解決方案",
                        info_heading: "關於 TCF Canada",
                        info_slogan: "您需要了解的一切",
                        writing_topic: '最新寫作題目',
                        listening_topic: '最新聽力題目',
                        insight: '見解',
                        test_center: '考試中心',
                        official_link: '官方鏈接',
                    },
                    profile: {
                        no_plan: "未訂閱",
                        membership_intro: "您的完整訪問權限將持續到",
                        extend_membership: "延長會員資格",
                        update_password: "更新密碼",
                        account: "帳戶",
                        check_subscription: "查看訂閱頁面",
                        non_membership_header: "訂閱以訪問以下資源",
                        membership_header: "您是會員，可以訪問以下資源",
                        membership_right_1: "36個真實的 TCF Canada 聽力測試 + 無限 AI 輔導",
                        membership_right_2: "37個真實的 TCF Canada 閱讀測試 + 無限 AI 輔導",
                        membership_right_3: "來自真實 TCF 考試的最新口語題 + AI 評估和輔導",
                        membership_right_4: "來自真實 TCF 考試的最新寫作話題 + AI 評估和輔導",
                        change_password_field_missing: "缺少必填字段",
                        password_not_match: "密碼不匹配",
                        change_password_failed: "密碼更改失敗",
                    },
                    comprehension: {
                        become_member_intro: "您需要成為會員才能訪問所有的試題。",
                        become_member_button: "成為會員",
                        listening_test_label_prefix: "CO - 聽力測試 ",
                        reading_test_label_prefix: "CE - 閱讀測試 ",
                        test: "測試",
                        train: "練習",
                    },
                    test_question: {
                        previous_question: "上一題",
                        next_question: "下一題",
                        transcript: "原文",
                        breakdown: "詳解",
                        show_transcript: "顯示原文",
                        show_breakdown: "顯示詳解",
                        original_text: "原文",
                        translation: "翻譯",
                        key_vocabulary_and_expressions: "關鍵詞彙和表達",
                        reference_answer: "參考答案",
                        show_analysis: "顯示分析",
                        summary: "總結",
                        translation_analysis: "翻譯和分析",
                        options: '選項',
                        explanation: '解釋',
                        conclusion: '結論',
                        finish: "完成",
                        confirm_finish_test: "確認完成測試？",
                    },
                    test_result: {
                        test_result: "測試結果",
                        correct: "正確",
                        wrong: "錯誤",
                        answered: "已回答",
                        not_answered: "未回答",
                        total_score: "總分",
                        score: "得分",
                        correct_answer: "正確答案",
                        your_answer: "您的答案",
                        under_levels: "低於 4"
                    },
                }
            }
        }
    });

export default i18n;
