import React, { useEffect, useState } from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import theme from './themes/theme';
import './common/i18n';
import Header from './components/header/Header';
import loginBackground from './media/Toronto_2.png';
import serviceBackground from './media/Toronto_3.jpg';
import IsLogin from './router/IsLogin';
import Payment from './pages/CheckoutForm';
import Return from './pages/CheckoutReturn';
import { LoadingProvider } from "./components/Context/LoadingContext";
import WechatPayment from './pages/WechatPayment';

// 自适应单位: vw, vh, vmin, vmax, svw, svh, lvw, lvh, dvw, dvh, pvw, pvh, wv, wh, wmin, wmax, sw, sh, lw, lh, dw, dh, pw, ph

const AppContent = () => {

    const Login = React.lazy(() => import('./pages/Login'));
    const Register = React.lazy(() => import('./pages/Register'));
    const Profile = React.lazy(() => import('./pages/Profile'));
    const Homepage = React.lazy(() => import('./pages/Homepage'));
    const Comprehension = React.lazy(() => import('./pages/Comprehension'));
    const Subscription = React.lazy(() => import('./pages/Subscription'));
    const CheckoutResult = React.lazy(() => import('./pages/CheckoutResult'));

    const [backgroundImage, setBackgroundImage] = useState(serviceBackground);
    const location = useLocation();

    useEffect(() => {
        const pathname = location.pathname.toLowerCase();
        if (pathname === '/login' || pathname === '/register') {
            setBackgroundImage(loginBackground);
        } else {
            setBackgroundImage(serviceBackground);
        }
    }, [location]);

    return (
        <Box minHeight="100vh"
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundAttachment="fixed"
            overflowY="auto"
            backgroundImage={`url(${backgroundImage})`}>
            <Box>
                {/*header height: 56px*/}
                <Header />
            </Box>
            <Box paddingTop={`80px`}>
                <Routes>
                    <Route path={"/Login"}
                        element={<React.Suspense fallback={<div>Loading...</div>}>
                            <Login /></React.Suspense>} />
                    <Route path={"/Register"}
                        element={<React.Suspense fallback={<div>Loading...</div>}>
                            <Register /></React.Suspense>} />

                    <Route path={"/Profile"}
                        element={<IsLogin><React.Suspense fallback={<div>Loading...</div>}>
                            <Profile /></React.Suspense></IsLogin>} />
                    <Route path={"/"}
                        element={<IsLogin><React.Suspense fallback={<div>Loading...</div>}>
                            <Homepage /></React.Suspense></IsLogin>} />
                    <Route path={"/Comprehension"}
                        element={<IsLogin><React.Suspense fallback={<div>Loading...</div>}>
                            <Comprehension /></React.Suspense></IsLogin>} />
                    <Route path={"/Subscription"}
                        element={<IsLogin><React.Suspense fallback={<div>Loading...</div>}>
                            <Subscription /></React.Suspense></IsLogin>} />
                    <Route path={"/Payment"}
                        element={<IsLogin><React.Suspense fallback={<div>Loading...</div>}>
                            <Payment /></React.Suspense></IsLogin>} />
                    <Route path={"/Return"}
                        element={<React.Suspense fallback={<div>Loading...</div>}>
                            <Return />
                        </React.Suspense>} />
                    <Route path={"/ReturnResult"}
                        element={<React.Suspense fallback={<div>Loading...</div>}>
                            <CheckoutResult />
                        </React.Suspense>} />
                    <Route path={"/WechatPayment"}
                        element={<WechatPayment />} />
                </Routes>
            </Box>
        </Box >
    );
};

const App = () => {
    return (
        <ChakraProvider theme={theme}>
            <LoadingProvider>
                <BrowserRouter>
                    <AppContent />
                </BrowserRouter>
            </LoadingProvider>
        </ChakraProvider>
    );
};

export default App;
